import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'
import { FaBars } from '@react-icons/all-files/fa/FaBars'
import { FaTimes } from '@react-icons/all-files/fa/FaTimes'

import { ImageCodingBeaverLogo } from './ImageComponents'

import useOnClickOutside from '../customHooks/useOnClickOutside'

import { navbarMenu, PageLinkUrl } from '../constants'

const DesktopMenu = () => {
  return (
    <ul className="hidden md:flex gap-4 text-lg items-center">
      {navbarMenu.map((item) => (
        <li>
          <Link
            className="py-2 px-2 lg:px-4 hover:bg-gray-100 rounded-md transition-colors"
            to={item.href}
          >
            {item.title}
          </Link>
        </li>
      ))}
      <li>
        <Link
          className="py-2 px-6 bg-beaver-green hover:bg-beaver-green-dark text-white transition-colors rounded-md"
          to={PageLinkUrl.INDEX + '#learning-channels'}
        >
          เริ่มเรียนเลย
        </Link>
      </li>
    </ul>
  )
}

const MobileMenuListItem = ({ href, title, closeMenu }) => {
  return (
    <li>
      <Link
        className="focus:bg-beaver-green block p-4"
        to={href}
        onClick={closeMenu}
      >
        {title}
      </Link>
    </li>
  )
}

const MobileMenu = () => {
  // states
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  // helper functions
  const closeMenu = () => setIsMenuOpen(false)

  // refs
  const mobileMenuRef = useRef(null)

  // helper
  useOnClickOutside(mobileMenuRef, closeMenu)

  return (
    <>
      <div className="md:hidden p-2">
        {isMenuOpen ? (
          <FaTimes className="text-4xl" />
        ) : (
          <FaBars onClick={() => setIsMenuOpen(true)} className="text-4xl" />
        )}
      </div>
      {isMenuOpen && (
        <div ref={mobileMenuRef} className="absolute left-0 w-full top-20 z-10">
          <div className="bg-white shadow-md z-20">
            <ul className="text-lg">
              {navbarMenu.map((item) => (
                <MobileMenuListItem
                  href={item.href}
                  title={item.title}
                  closeMenu={closeMenu}
                />
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

const Navbar = () => {
  return (
    <nav
      className="relative shadow-md"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container-xl flex justify-between items-center py-4">
        <div className="font-bold text-xl">
          <Link to="/" className="navbar-item" title="Logo">
            <ImageCodingBeaverLogo />
          </Link>
        </div>
        <DesktopMenu />
        <MobileMenu />
      </div>
    </nav>
  )
}

export default Navbar
