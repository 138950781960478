import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

export const ImageCodingBeaverTeacher = () => {
  return (
    <StaticImage
      className="rounded-full"
      style={{
        WebkitMaskImage:
          '-webkit-radial-gradient(circle, white 100%, black 100%)',
      }}
      src="../images/coding-beaver-friendly.JPG"
      alt="Coding Beaver Teacher"
      placeholder="blurred"
      layout="constrained"
      height="400"
      width="400"
      quality="70"
    />
  )
}

export const ImageCodingBeaverGradSmile = () => {
  return (
    <StaticImage
      className="rounded-full border-4 border-beaver-green z-0"
      style={{
        WebkitMaskImage:
          '-webkit-radial-gradient(circle, white 100%, black 100%)',
      }}
      src="../images/coding-beaver-graduate-smile.jpeg"
      alt="Coding Beaver graduate smiling"
      placeholder="blurred"
      layout="constrained"
      height="400"
      width="400"
      quality="70"
    />
  )
}

export const ImageCodingBeaver = () => {
  return (
    <StaticImage
      className="z-0 -mb-10 md:mb-0"
      src="../images/codingBeaver_blueFrame.png"
      alt="Coding Beaver"
      placeholder="blurred"
      layout="constrained"
      height="600"
      width="600"
      quality="100"
    />
  )
}

export const ImageCodingBeaverLogo = () => {
  return (
    <StaticImage
      className="z-0 -my-8 sm:-my-6"
      src="../images/codingBeaver3820x1920.png"
      alt="Coding Beaver Logo"
      placeholder="blurred"
      layout="constrained"
      height="96"
      width="191"
      quality="100"
    />
  )
}
