import React from 'react'
import { Link } from 'gatsby'

import { PageLinkUrl, footerMenu } from '../constants'

import { getCopyrightDate } from '../libs/getCopyrightDate'

const Footer = () => {
  return (
    <footer className="bg-beaver-blue-dark">
      <div className="container max-w-screen-xl mx-auto px-4 text-center py-16 text-beaver-blue">
        <div className="flex flex-col justify-center sm:flex-row gap-4 sm:gap-20">
          <div className="text-left">
            <Link to={PageLinkUrl.INDEX}>
              <h2 className="font-bold text-xl inline-block text-white">
                Coding Beaver
              </h2>
            </Link>
            <p className="max-w-xs my-12">
              <span className="font-bold">Coding Beaver</span>{' '}
              เชื่อว่าทุกคนมีศักยภาพในการเรียนรู้
              และพร้อมที่จะก้าวไปทีละก้าวกับทุกคน สู่การเป็น Developer{' '}
              ที่ดีไปด้วยกัน
            </p>
          </div>
          <div className="w-px bg-beaver-blue"></div>
          <div>
            <h3 className="text-white font-medium text-left">เริ่มเรียนเลย</h3>
            <ul className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
              {footerMenu.map((item) => {
                return (
                  <Link
                    className="px-3 py-2 rounded-md hover:bg-beaver-blue hover:text-beaver-blue-dark transition-colors"
                    to={item.url}
                  >
                    <li className="flex items-center gap-1">
                      <item.icon />
                      <p className="text-left">{item.name}</p>
                    </li>
                  </Link>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      <small
        style={{ fontFamily: 'sans-serif' }}
        className="block bg-beaver-blue-dark p-4 text-beaver-blue text-center"
      >
        <span>&copy;</span> Copyright {getCopyrightDate()}, All rights reserved.
      </small>
    </footer>
  )
}

export default Footer
