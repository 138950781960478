import { FaYoutubeSquare } from '@react-icons/all-files/fa/FaYoutubeSquare'
import { FaRegFileAlt } from '@react-icons/all-files/fa/FaRegFileAlt'
import { FaLink } from '@react-icons/all-files/fa/FaLink'
import { FaChild } from '@react-icons/all-files/fa/FaChild'

export enum PageLinkUrl {
  RESOURCE = '/resource',
  BLOG = 'https://blog.coding-beaver.tech',
  ABOUT = '/about',
  INDEX = '/',
  YOUTUBE = 'https://www.youtube.com/channel/UCNPxaf4kKjkNkP9hgtwdb5w',
}

export enum PageName {
  BLOG = 'บทความ',
  RESOURCE = 'แหล่งเรียนรู้เพิ่มเติม',
  ABOUT = 'รู้จักเรา',
  YOUTUBE = 'Youtube',
}

export const navbarMenu = [
  {
    title: PageName.BLOG,
    href: PageLinkUrl.BLOG,
  },
  {
    title: PageName.RESOURCE,
    href: PageLinkUrl.RESOURCE,
  },
  {
    title: PageName.ABOUT,
    href: PageLinkUrl.ABOUT,
  },
]

export const footerMenu = [
  {
    name: PageName.YOUTUBE,
    url: PageLinkUrl.YOUTUBE,
    icon: FaYoutubeSquare,
  },
  {
    name: PageName.BLOG,
    url: PageLinkUrl.BLOG,
    icon: FaRegFileAlt,
  },
  {
    name: PageName.RESOURCE,
    url: PageLinkUrl.RESOURCE,
    icon: FaLink,
  },
  {
    name: PageName.ABOUT,
    url: PageLinkUrl.ABOUT,
    icon: FaChild,
  },
]
